@import url("https://fonts.maateen.me/solaiman-lipi/font.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "SolaimanLipi", Arial, sans-serif !important;
  background-color: #fff;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
